// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-documentation-src-templates-doc-js": () => import("/tmp/7465f8d3/node_modules/gatsby-theme-documentation/src/templates/doc.js" /* webpackChunkName: "component---node-modules-gatsby-theme-documentation-src-templates-doc-js" */),
  "component---src-pages-stats-js": () => import("/tmp/7465f8d3/src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-about-mdx": () => import("/tmp/7465f8d3/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-index-mdx": () => import("/tmp/7465f8d3/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-learn-results-mdx": () => import("/tmp/7465f8d3/src/pages/learn/results.mdx" /* webpackChunkName: "component---src-pages-learn-results-mdx" */),
  "component---src-pages-learn-specificity-mdx": () => import("/tmp/7465f8d3/src/pages/learn/specificity.mdx" /* webpackChunkName: "component---src-pages-learn-specificity-mdx" */),
  "component---src-pages-uses-mdx": () => import("/tmp/7465f8d3/src/pages/uses.mdx" /* webpackChunkName: "component---src-pages-uses-mdx" */)
}


import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "wayback-css-build-status-js-standard-style"
    }}>{`wayback-css `}<a parentName="h1" {...{
        "href": "https://travis-ci.org/cssstats/wayback-css"
      }}><img parentName="a" {...{
          "src": "https://secure.travis-ci.org/cssstats/wayback-css.svg?branch=master",
          "alt": "Build Status"
        }}></img></a>{` `}<a parentName="h1" {...{
        "href": "https://github.com/feross/standard"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/code%20style-standard-brightgreen.svg?style=flat",
          "alt": "js-standard-style"
        }}></img></a></h1>
    <p>{`Get the css for a wayback machine url.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install --save wayback-css
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const waybackCss = require('wayback-css')

waybackCss('google.com', '20151221') // => YYYYMMDDhhss timestamp format
  .then(doStuff)
  .catch(handleError)
`}</code></pre>
    <h2 {...{
      "id": "license"
    }}>{`License`}</h2>
    <p>{`MIT`}</p>
    <h2 {...{
      "id": "contributing"
    }}>{`Contributing`}</h2>
    <ol>
      <li parentName="ol">{`Fork it`}</li>
      <li parentName="ol">{`Create your feature branch (`}<inlineCode parentName="li">{`git checkout -b my-new-feature`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Commit your changes (`}<inlineCode parentName="li">{`git commit -am 'Add some feature'`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Push to the branch (`}<inlineCode parentName="li">{`git push origin my-new-feature`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Create new Pull Request`}</li>
    </ol>
    <p>{`Crafted with <3 by John Otander (`}<a parentName="p" {...{
        "href": "https://twitter.com/4lpine"
      }}>{`@4lpine`}</a>{`).`}</p>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`This package was initially generated with `}<a parentName="p" {...{
          "href": "http://yeoman.io"
        }}>{`yeoman`}</a>{` and the `}<a parentName="p" {...{
          "href": "https://github.com/johnotander/generator-p.git"
        }}>{`p generator`}</a>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      